import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import { adminRequestPost } from "../../apis/request";
import Send from "./send.png";
import { ReactComponent as Xmark } from "../../assets/icon/svgs/solid/xmark.svg";

function PremiumMadal({ setCallModal, postLike, onPremiumModal , onErrorModal}) {
  const [value, setValue] = useState("");
  const textareaRef = useRef(null);

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const callapi = () => {
    if(value && value.length > 30) {
      setCallModal(false);
      onPremiumModal(value);
    } else {
      onErrorModal("호감 메세지는 최소 30자 이상 작성하셔야 합니다.");
      textareaRef.current.focus();
    }
    
  };

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "auto"; // 높이 초기화
      const newHeight = Math.min(textarea.scrollHeight, 5 * 24); // 최대 5줄까지 허용
      textarea.style.height = `${newHeight}px`; // 새로운 높이 설정
    }
  }, [value]);

  const handleChange = (event) => {
    if (event.target.value.length <= 500) {
      setValue(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="custommodalWrap">
        <Row className="h-100 d-flex align-items-center justify-content-center p-0 m-0">
          <Col className="h-100 p-0 m-0" style={{ position: "relative" }}>
            <div className="modalOverlay" />
            <Card
              className="modalCard w-100"
              style={{
                position: "absolute",
                bottom: 0,
                padding: "20px 20px 45px 20px",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-end primiumMainText"
                  style={{ marginBottom: "-10px" }}
                >
                  <button
                    onClick={() => setCallModal(false)}
                    className="noneBtn"
                  >
                    <Xmark
                      width={20}
                      height={20}
                      onClick={() => setCallModal(false)}
                    />
                  </button>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex p-0 mx-0 justify-content-center primiumMainText"
                >
                  <img
                    src={Send}
                    alt="sendicon"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumMainText"
                >
                  호감으로 진심을 전해보세요.
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0 text-center primiumSubText"
                >
                  {`메세지 입력 후 확인을 누르시면\nSMS로 매칭 알림이 전송됩니다.`}
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col lg={12} className="d-flex justify-content-between p-0 m-0">
                  <div className="primiumMsgText">메세지 보내기</div>
                  <div className="primiumMsgText">{value.length}/500</div>
                </Col>
              </Row>
              <Row className="w-100 px-0 m-0">
                <Col
                  lg={12}
                  className="d-flex flex-column p-0 mx-0"
                  style={{ marginBottom: "24px" }}
                >
                  <div>
                    <textarea
                      ref={textareaRef}
                      className="form-control"
                      value={value}
                      onChange={handleChange}
                      placeholder={`메세지를 30자 이상 작성해 주세요 (최대 500자)`}
                      aria-label={`메세지를 30자 이상 작성해 주세요 (최대 500자)`}
                      rows={3}
                      style={{
                        padding: "10px",
                        resize: "none",
                        boxSizing: "border-box",
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col className="m-0" style={{ padding: "0px 0px 0px 0px" }}>
                  <Button id="primiumAccesBtn" onClick={() => callapi()}>
                    확인
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default PremiumMadal;
