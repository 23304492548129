import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Container, Row, Modal, Input, Button } from "reactstrap";
// import BackImg from "../../assets/images/small/img-4.jpg";
//i18n
import { withTranslation } from "react-i18next";
import BackImg09301 from "../../assets/img/others/pc_problem_0930_1.png";
import BackImg0930_mo from "../../assets/img/others/mo_problem_0930_1.png";
import "../Dashboard/dashboard.scss";
//lch1721 /problem 기존의 문제점 
const Whypage = (props) => {
  const [currentImg, setCurrentImg] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };

    // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 초기 로딩 시 화면 너비 체크
    handleResize();
    document.title = "기존의 문제점 | 우리의 인연";
    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <div className="border-bottom">
        {currentImg > 991 ? (
          <div>
            <img alt="mainpage" className="w-100" src={BackImg09301} />
          </div>
        ) : (
          <div>
            <img alt="mainpage" className="w-100" src={BackImg0930_mo} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Whypage.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Whypage);
