import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { requestGet } from "../../apis/request";
import "./purcahsecopy.scss";

function Products(props) {
  const [nomalData, setNomalData] = useState([]);
  const [earlyData, setEarlyData] = useState([]);

  const history = useHistory();

  const callApi = async () => {
    const response = await requestGet(`/api/product/joinfee/list`);
    let arr = [];
    let arr1 = [];
    if (response?.data) {
      response.data.forEach((item) => {
        if (item.name.includes("얼리버드")) {
          arr.push(item);
        } else {
          arr1.push(item);
        }
      });
    }
    setEarlyData(arr);
    setNomalData(arr1);
  };

  useEffect(() => {
    callApi();
  }, []);

  const goPurchase = (item) => {
    history.push({
      pathname: `/purchase`,
      state: {
        productId: item.id,
        name: item.name,
        amount: item.price,
        desc: item.desc,
      },
    });
  };

  useEffect(() => {
    document.title = "서비스 안내 | 우리의 인연";
  }, []);

  return (
    <React.Fragment>
      <div className="page-content customWrap">
        <Container>
          <h1 className="purMainTitle">결제 안내</h1>
          <Row>
            <Col className="purSubTitle">얼리버드 회원가입비</Col>
          </Row>
          <Row className="purRow">
            {earlyData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              const otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "")
                .replace("[", "")
                .replace("]", "");
              return (
                <Col lg={6} key={i}>
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemName">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                        <span className="purCardItemVAT">(VAT 포함)</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemDesc">{otherContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemSubDesc">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <button
                          className="purCardItemBtn"
                          onClick={() => goPurchase(item)}
                        >
                          구매하기
                        </button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col className="purSubTitle">정상가 회원가입비</Col>
          </Row>
          <Row>
            {nomalData?.map((item, i) => {
              const match = item.desc.match(/\[(.*?)\]/);
              const bracketContent = match
                ? match[0].replace("[", "").replace("]", "")
                : ""; // 대괄호 내용
              const otherContent = item.desc
                .replace(bracketContent, "")
                .trim()
                .replace("<br>", "")
                .replace("[", "")
                .replace("]", "");
              return (
                <Col lg={6} key={i}>
                  <Card className="purCard">
                    <Row>
                      <Col className="purCardItemName">{item.name}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemPrice">
                        {String(item.price).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        ) + "원"}
                        <span className="purCardItemVAT">(VAT 포함)</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemDesc">{otherContent}</Col>
                    </Row>
                    <Row>
                      <Col className="purCardItemSubDesc">{bracketContent}</Col>
                    </Row>
                    <Row>
                      <Col>
                        <button
                          className="purCardItemBtn"
                          onClick={() => goPurchase(item)}
                        >
                          구매하기
                        </button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Products;
