import React from "react";
import { Row } from "reactstrap";

function Money({ handleChange, SelectFn, values, handleMoneyChange }) {
  const selOption = [
    {
      name: "유동자산",
      key: "movemoney",
      type: "money",
    },
    {
      name: "비유동자산(부동산 포함)",
      key: "nomovemoney",
      type: "money",
    },
    {
      name: "비유동자산(부동산 포함) 소유여부",
      key: "hasnomovemoney",
      type: "radio",
      value: ["없음", "있음"],
    },
    {
      name: "혼인 시 경제적 지원",
      key: "merrymoney",
      type: "radio",
      value: ["없음", "있음"],
    },
    {
      name: "차량 유무",
      key: "hascar",
      type: "radio",
      value: ["없음", "자차", "개인렌트/리스", "법인렌트/리스"],
    },
    {
      name: "차량 제조사",
      key: "makecarname",
      type: "text",
    },
    {
      name: "차량 모델명",
      key: "carname",
      type: "text",
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">자산정보</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "money") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            value: el.value,
            values,
            handleChange,
            handleMoneyChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            values,
            type: el.type,
            handleChange,
            handleMoneyChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Money;
