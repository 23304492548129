import React from "react";
import { Row } from "reactstrap";

function Hope({ handleChange, handleCheck, SelectFn, values, handleCheckstr }) {
  const selOption = [
    {
      name: "최소 나이(연나이 기준)",
      key: "ideal_birthYearMin",
      type: "number",
    },
    {
      name: "최대 나이(연나이 기준)",
      key: "ideal_birthYearMax",
      type: "number",
    },
    {
      name: "외모",
      key: "ideal_appearance",
      type: "radio",
      value: [
        "상관없음",
        "평범함",
        "호감형",
        "약간 매력적",
        "매력적",
        "매우 매력적",
        "기타",
      ],
    },
    {
      name: "최소 키",
      key: "ideal_heightMin",
      type: "number",
    },
    {
      name: "최대 키",
      key: "ideal_heightMax",
      type: "number",
    },
    {
      name: "최소 학력",
      key: "ideal_education",
      type: "radio",
      value: [
        "상관없음",
        "스카이",
        "인서울4년제",
        "지방국공립",
        "해외대학",
        "전문대",
        "기타",
      ],
    },
    {
      name: "직업분류",
      key: "ideal_industry",
      type: "check",
    },
    {
      name: "고용형태",
      key: "hateEmploymentType",
      type: "checks",
      value: [
        "상관없음",
        "프리랜서",
        "법인사업자",
        "개인사업자",
        "가업승계",
        "정규직",
        "위촉직",
        "계약직",
      ],
    },
    {
      name: "소득",
      key: "ideal_income",
      type: "radio",
      value: [
        "상관없음",
        "4천만원 미만",
        "4천만원 이상 6천만원 미만",
        "6천만원 이상 8천만원 미만",
        "8천만원 이상 1억원 미만",
        "1억원 이상 3억원미만",
        "3억원 이상",
      ],
    },
    {
      name: "본인자산",
      key: "ideal_personalAsset",
      type: "radio",
      value: [
        "상관없음",
        "0~5천만원",
        "5천~1억",
        "1억~3억",
        "3억~5억",
        "5억~10억",
        "10억~30억",
        "30억 이상",
      ],
    },
    {
      name: "부동산 자산유무",
      key: "ideal_hasImmovable",
      type: "radio",
      value: ["상관없음", "있음", "기타"],
    },
    {
      name: "부모님 자산",
      key: "ideal_parentalAsset",
      type: "radio",
      value: [
        "상관없음",
        "1~5억 사이",
        "5~10억 사이",
        "10~30억 사이",
        "30~50억 사이",
        "50~100억 사이",
        "100억 이상",
      ],
    },
    {
      name: "종교",
      key: "ideal_religion",
      type: "radio",
      value: ["무교", "천주교", "불교", "기독교", "상관없음", "기타"],
    },
    {
      name: "만남 가능 지역",
      key: "ideal_allowRegion",
      type: "checks",
      value: [
        "전국",
        "서울",
        "인천",
        "경기 남부",
        "경기 북부",
        "부산/경남",
        "대구/경북",
        "전라도",
        "세종/대전/충청",
        "강원",
        "제주",
      ],
    },
    {
      name: "성격",
      key: "ideal_caracter",
      type: "text",
    },
    {
      name: "결혼여부",
      key: "hateMarriageHistory",
      type: "radio",
      value: ["미혼", "사실혼", "무출산 재혼", "출산 재혼"],
    },
    {
      name: "딩크 여부",
      key: "isDink",
      type: "radio",
      value: ["예", "아니요"],
    },
    {
      name: "흡연",
      key: "hateSmokeType",
      type: "radio",
      value: ["비흡연", "흡연(연초)", "흡연(전자담배)", "금연예정", "기타"],
    },
    {
      name: "문신여부",
      key: "hateTatoo",
      type: "radio",
      value: [
        "상관없음",
        "작은거 1개",
        "작은거 2개 또는 큰문신 1개이상",
        "없음",
        "기타",
      ],
    },
    // {
    //   name: "부모님 정보",
    //   key: "hateParentStatus",
    //   type: "radio",
    //   value: ["상관없음", "일반가정", "이혼함", "재혼함", "편부모", "양친작고"],
    // },
    {
      name: "차량유무",
      key: "hateHasCarType",
      type: "radio",
      value: ["상관없음", "차량없음", "차량있음", "기타"],
    },

    {
      name: "우선순위 1순위",
      key: "priority1",
      type: "select",
      value: [
        "얼굴",
        "피지컬",
        "나이",
        "학력",
        "자산 및 집안",
        "직업 및 소득",
        "매력(성격)",
        "종교",
        "거리",
      ],
    },
    {
      name: "우선순위 2순위",
      key: "priority2",
      type: "select",
      value: [
        "얼굴",
        "피지컬",
        "나이",
        "학력",
        "자산 및 집안",
        "직업 및 소득",
        "매력(성격)",
        "종교",
        "거리",
      ],
    },
    {
      name: "우선순위 3순위",
      key: "priority3",
      type: "select",
      value: [
        "얼굴",
        "피지컬",
        "나이",
        "학력",
        "자산 및 집안",
        "직업 및 소득",
        "매력(성격)",
        "종교",
        "거리",
      ],
    },
  ];
  return (
    <React.Fragment>
      <h3 className="MainText">이상형 우선 순위</h3>
      <p className="card-title-desc"></p>
      <Row className="rowBorderBottom mt-3" />
      {selOption?.map((el, i) => {
        if (el.type === "text") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "number") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            handleChange,
          });
        } else if (el.type === "checks") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            value: el.value,
            values,
            key: i,
            handleCheckstr,
          });
        } else if (el.type === "check") {
          return SelectFn({
            name: el.name,
            type: el.type,
            keyProp: el.key,
            value: el.value,
            values,
            key: i,
            handleCheck,
          });
        } else if (el.type === "select") {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            values,
            handleChange,
          });
        } else {
          return SelectFn({
            name: el.name,
            keyProp: el.key,
            value: el.value,
            type: el.type,
            handleChange,
          });
        }
      })}
    </React.Fragment>
  );
}

export default Hope;
