import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import popupImage from "../../assets/img/others/popup_0717.jpg";
import "./dashboard.scss";

function DashboardModal({ setIsVisible }) {
  const [timesent, setTimesent] = useState(false);
  const history = useHistory();
  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  const hideForAnHour = () => {
    if (timesent) {
      const now = new Date().getTime();
      // const oneHourLater = now + 604800000;
      const oneHourLater = now + 86400000;
      localStorage.setItem("dashboardModal", oneHourLater.toString());
      setIsVisible(false);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <React.Fragment>
      <Container fluid className="modalWrap">
      <div className="amodal">
      <div className="amodal-content white">
        {/* <img alt="popupImage" className="popupImage" src={popupImage} /> */}
        <div className="amodal-header">
        <p>[가격 인상 안내]</p>

        <p>그동안 최소한의 비용으로 최선의 매칭서비스를 제공해드리고자 진행했던 횟수제와 기간제 4개월 프로그램 비용이</p>

        <p>10월 1일(화) 부로 횟수제 5회권 기존 110만원에서 140만원(VAT포함), 기간제 4개월 150만원에서 170만원 (VAT포함)으로 인상됩니다.</p>

        <p>이전 가격 적용은 </p>

        <p>1) 9월 30일까지 상담&결제 완료된 회원</p>

        <p>2)  9월7일(토) 이전에 신청하였으나 10월 1일(화) 이후 상담오셔서 상담&결제한 회원이오니</p>

        <p>이 점 널리 양해 부탁드립니다</p>

        <p>*가입비 인상과 별도로 당일 결제 시 기간서비스 추가 & 야외 사진촬영 지원& 미팅파티 할인 혜택이 확대되었으니 많은 관심과 상담신청 부탁드립니다 </p>
        </div>
        <div className="amodal-close-part">
        <input type="checkbox" checked={timesent} value={timesent} id="modalOneHour" 
                      onClick={() => setTimesent(!timesent)} />
          <label className="checklabel" onClick={() => {
            console.log("test");
            setTimesent(!timesent)}}>
              
              하루동안 보지 않기
            </label>
            <button className="aclose-button-none" onClick={() => hideForAnHour()}>
              닫기  X
            </button>
        </div>
        
        {/* <div className="amodal-footer"> */}
          {/* <label className="adont-show">
            <input type="checkbox" value={timesent} id="oneHour" 
                    onClick={() => setTimesent(!timesent)} />
            하루동안 보지 않기
          </label>
          <button className="aclose-button" onClick={() => hideForAnHour()}>
            닫기
          </button> */}
        {/* </div> */}
      </div>
    </div>
        
      </Container>
    </React.Fragment>
  );
}

export default DashboardModal;
