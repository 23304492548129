import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import backgroundImage from "./part_background.png";


import { useParams } from "react-router-dom"; 

import BackImg6 from "./img/n_1.jpeg";
import BackImg2 from "./img/n_w_check.jpeg";
import BackImg3 from "./img/n_a_1.jpeg";
import BackImg4 from "./img/n_w_br_s1.jpeg";
import BackImg5 from "./img/b_w_bl.jpg";
import BackImg1 from "./img/n_b_b_1.jpg";
import "./review.scss";

const PartyDetail = (props) => {
  const { id } = useParams();
  const [currentImg, setCurrentImg] = useState(null);
  const [data, setData] = useState([]);
  const [name, setName] = useState(null);
  const [stars, setStars] = useState(null);
  const [sentences, setSentences] = useState([]);
  const [dummy1] = useState([
    {
      id:6,
      header: "김**님의 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
지난 7월 20일, 강남의 럭셔리한 와인바에서 열린 우연 결혼정보회사의 와인 파티에 다녀왔습니다. 이 파티는 남녀 각각 18명씩, 총 36명의 잘생기고 아름다운 미혼남녀들이 모여 결혼과 연애에 대한 진지한 대화를 나누기 위해 마련된 자리였습니다. 그야말로 근사하고 분위기 좋은 와인바에서 진행된 이번 행사는 그 자체로도 흥미롭고 매력적인 시간이었습니다.

파티는 3대3으로 6명씩 총 6개의 테이블에서 진행되었으며, 남자들이 로테이션으로 이동하며 다양한 사람들과 만날 수 있는 기회를 제공했습니다. 테이블마다 제공된 와인은 레드와인과 화이트 와인으로, 모두 고급스럽고 맛있는 와인들이었습니다. 각 테이블마다 샐러드, 스테이크, 파스타, 치즈, 각종 과일 등 신선하고 맛있는 음식들이 제공되어, 음식과 와인 모두에서 높은 만족감을 느낄 수 있었습니다.

행사 중간중간, 사회자가 진행하는 게임과 유창하고 재미있는 사회는 처음 보는 참가자들의 긴장을 풀어주기에 충분했습니다. 36명의 남녀가 서먹함 없이 화기애애한 분위기 속에서 자연스럽게 어울릴 수 있도록 분위기를 이끌어 주어, 모두가 편안하게 대화할 수 있었습니다.

이 와인 파티의 큰 매력 중 하나는 약간의 용기만 있다면 서로 마음에 드는 이성의 연락처를 자유롭게 교환할 수 있다는 점이었습니다. 참가자들 모두가 결혼이나 연애에 관심이 있는 사람들이기 때문에, 자연스럽게 대화의 목적이 일치했고, 이는 파티의 분위기를 더욱 긍정적으로 만들어 주었습니다. 또한, 참석한 훈남 훈녀들이 많아서 시각적으로도 매우 즐거운 시간이었습니다.

이번 와인 파티의 참가비는 20만원이었는데, 고급 와인과 음식, 그리고 유쾌한 사회와 게임 진행 등을 고려할 때 매우 합리적인 금액이라고 생각합니다. 결혼정보회사에서 주최하는 만큼, 참가자들의 진지한 의도를 확인할 수 있었고, 이는 파티의 신뢰성을 높여주었습니다.

결혼정보회사 우연의 회원 중에서 결혼이나 연애에 관심이 있거나, 와인 파티가 처음이신 분들께 이 자리를 강력히 추천하고 싶습니다. 20만원이라는 참가비가 전혀 아깝지 않은 자리였으며, 새로운 사람들과의 만남을 통해 의미 있는 시간을 보낼 수 있었습니다. 앞으로도 이런 기회가 있다면 다시 참여하고 싶은 마음이 들 정도로 만족스러운 시간이었습니다.

우연 결혼정보회사에서 주최한 이번 와인 파티는, 결혼과 연애에 대한 진지한 대화를 나눌 수 있는 좋은 기회였고, 고급 와인과 음식, 그리고 유쾌한 분위기 속에서 소중한 인연을 만날 수 있는 특별한 시간이었습니다. 이러한 경험을 통해 새로운 만남의 기회를 찾고 계신 분들께 이 파티를 강력히 추천드립니다.
      `,
      date: "24.07.29",
      image:BackImg6,
      ratio: "2400:1600",
    },
    {
      id:5,
      ratio: "3000:2000",
      image:BackImg2,
      header: "최**님의 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
파티관련 후기 남깁니다.

1. 파티 장소가 너무 만족스럽습니다!! 조명도 은은하니 분위기도 좋고 설레는 마음이 막 생기는 장소였습니다.

2. 음식이 너무 맛있었습니다. 대화에 집중한다고 충분히 먹지는 못했지만 조금씩 먹은 음식들이 너무나도 맛있어서 기분이 좋아졌습니다!! ㅎㅎ

3. 중간중간 경품 추천을 하면서 아이스브레이킹이 되니깐 분위기도 풀리면서 대화하는데 더 편해지는 느낌이라 좋았습니다!! ㅎㅎ

4. 처음에 대표님과 김달님이 좋은 말씀을 해주셔서 뭔가 더 전투적으로 미팅에 임하게 되어서 좋았습니다. 좋은 인연을 찾기 위해 더 집중해서 대화를 나눌 수 있었던것 같습니다!! ㅎㅎ

5. 처음에 매니저님들이 담당 회원님들 긴장 풀어주려고 다가와서 재밌게 얘기도 해주시고 중간중간 테이블에 필요한게 없는지 수시로 체크해주시면서 신경써주시는게 감동이였습니다!! 

6. 파티가 끝나고 2차 장소까지 정해주셔서 안내해주시는 것도 좋았습니다!! 마지막까지 챙겨주시는 모습에 또 한번 감동을 ㅎㅎ
      `,
      date: "24.05.11",
    },
    {
      id:4,
      image:BackImg3,
      ratio: "3000:2000",
      header: "강**님 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
저는 우선 결정사 파티에 참여해본적이 처음이었는데요 ㅋㅋㅋ 불특정 다수와 상대하는게 힘들어서ㅎㅎ 처음에는 가고싶은 마음이 없었지만 김달님이 오신다는 말에 참석하게 되었는데.. 김달님을 직접 뵐 수 있어서 ㅋㅋㅋㅋㅋ제 목적이 달성되어 좋았습니다.

장점은
1. 다양한 사람들과 대화를 나눌수 있어서 좋았어요.

2. 딱딱하지 않고 부드러운 분위기가 편안했습니다 

3. 사회 봐주시는 분도 경품 추첨도 재밌었습니당 

4. 40분씩 로테이션 하는 것도 재밌었어요

5. 여자도 남자도 전체적으로 훈훈하신 분들이 많이오셔서 저는 나쁘지 않고 좋았습니다 

6. 음식도 다양하게 6~7가지 푸짐하고 맛있게 나오고, 와인도 종류별로 제공해주셔서 그부분도 만족스러웠어요(특히 모스카토 너무 맛있었어요!)

7. 파티 위치도 강남으로 접근성 좋았고, 공간 자체도 어두우면서도 조명이 예뻐서 분위기가 딱 좋았어요
      `,
      date: "24.05.11",
    },
    {
      id:3,
      ratio: "3000:2000",
      image:BackImg4,
      header: "이**님 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
🥂우연 오픈파티 (솔직한) 후기🥂 


👍넘 좋았던 점👍

▫️분위기
: 매니저님들 & 김달님 & 대표님까지 모두 총출동하신 모습 그 자체로 얼마나 참여한 회원분들을 소중히 여기고 이 행사의 성료를 기원하는지 느껴져서, 환영 받고 대접 받는 분위기가 정말 좋고 감사한 마음이었습니다. 세상 누구든 자신이 중요한 사람으로 여겨지는 걸 기뻐하는데 그런 마음이 들게끔 맞아주셔서 다시 한 번 정말 감사합니다.

▫️모임장소
: 약간은 어두운 조명의 분위기가 참 좋았습니다. 초면에는 너무 밝은 곳보단 조금 어두운 느낌이 좋겠다 싶었는데 센스 있게 장소 선정해주셔서 어색한 마음이 누그러지는데 한 몫 했던 것 같아요.

▫️2행시 미션
: 약속장소에 일찍 도착한 분들은 뭔가 되게 뻘쭘할 수 있을텐데 2행시 미션이 있어서 (뭐라도 하면서 어색함 달래기ㅎㅎ) 이 부분이 넘 좋았습니다. 새로 오시는 분들께도 2행시 종이 나눠드리면서 말 걸 수 있어서 다행이다 싶었습니다☺️

▫️지정석
: 호불호가 갈리겠지만 지정석이 있어서 전 좋았습니다. 어색한 자리일수록 신중하고 수동적이기 마련인데, 아예 앉을 자리로 안내해주시니 마음이 더 편하더라고요. 또 격식 있고 고급스러운 자리일수록 자유석보단 지정석인 경우가 많다 보니 더욱 신경 써서 대접해주신다는 느낌이 들어 좋았습니다. 


프로필은 받기 까지 시간이 많이 소요가 되는데 여러사람과 실물 보고 서로 이야기 할 수 있었던 점이 좋았습니다. 결정사 특성상 많은 조건들을 알고 사람을 만나게 되는데 이게 장점이자 단점이라고 생각하거든요, 물론 중요하지만 사람의 매력은 조건으로만은 판단 할 수 없는데 그 아쉬웠던 점을 희석 할 수 있는 자리가 아니였을까 싶어요.

아마 많은 분들이 좋은 이성을 만나기 위해서 우연에 왔을텐데 자연스러운 만남까지 할 수 있는 공간을 직접 만들어주셔서 만족도가 높지 않을까 싶습니다:)

Mc분도 있고 로테이션 텀이나 이벤트 등 으로 어색하지 않았던거 같아요! 김달님, 대표님, 모든 관계자분들 다 오셔서 케어해주시는 모습이 신경을 많이 쓰시는구나 느꼈습니다😊
      `,
      date: "24.05.11",
    },
    {
      id:2,
      ratio: "2400:1600",
      image:BackImg5,
      header: "박**님의 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
*우연 와인 파티 후기*

일단 팬이었던 김달님을 만나게 되어서 영광이었고 김달님께 결혼에 대한 여러 가지 질문들을 하면서 소중한 시간을 보낸 것 같습니다!

소개서나 호감 메시지들은 정량적인 내용이 들어있다 보니 이것저것 따지게 된다면.. 우연 파티를 통해선 서로의 나이, 직업만 알고 성격만으로 이 사람을 파악하게 되니깐 부담 없이 얘기할 수 있었고 좋았습니다! 또 살면서 만나보지 못했을 다양한 직군의 사람들을 만날 수 있는 기회였습니다! 비슷한 직군분들과 얘기할 때는 내적 친밀감이 생겼다랄까..

서로 같은 목적을 가지고 가입을 했지만 가치관이 다른 사람이 많았고 남자분들은 배려심이 많은 여자를 이상형으로 많이 꼽는다는 것도 알게 되었어요~!

생각보다 훌륭하신 분들이 너무 많아가지고.. 하루라도 더 젊을때 가입해서 좋은 남자 찾으려 노력하길 잘했다는 생각이..🥹

너무 귀한 시간이었고 이런 파티를 통해 좀 더 자기개발하여 좋은 인연을 만나야겠다 생각했습니다! ㅎㅎ 좋은 기회 주셔서 감사합니당~🩷🩷🩷
      `,
      date: "24.05.11",
    },
    {
      id:1,
      ratio: "3000:2000",
      image:BackImg1,
      header: "한**님의 파티 후기",
      stars: 5,
      name: "우연 와인 파티 후기",
      desc: `
<파티후기> 

- 음식: 넘 맛있었어요. 샐러드부터 파스타, 스테이크, 후식, 와인 안주 등 다양하게 나와서 입이 즐거우니 기분도 좋아서 대화하는데 힘이 났어요! 그러면서 서로 자연스레 음식매너도 알아볼 수 있겠죠?

- 분위기 : 저녁시간대라 감성이 뿜뿜하는 분위기가 될 수 있었던 것 같아요. 어두운 적당한 조명과 와인이 반절 다 했어요!!😆

- 사람들의 외적매력(물론 상중 분포는 있었겠지만): 엄선해서 선정하셨는지, 다른 결정사 파티도 가보면... 외적매력이 아쉬운 분도 계시고 했는데 여긴 달랐어요! 확실히 상담과 매칭 모두 한분이 하시니까, 대화도 완만히 잘 풀어가실줄 아는 매력 남녀들이어서 시간이 너무 순삭되어 아쉬울 정도였어요. 

- 운영: 다른 결정사에선.. 다른데 시간을 많이 소비해서 10분 로테이션으로 넘 짧고 그랬는데, 우연은 대화 집중 시간이 충분하고 서로 자기소개하고 질문하고, 가치관 대화까지 할 정도로, 서로 어떤 사람이구나 느낌을 알아갈 수 있어서 넘 좋았어요.  아주 만족스러웠습니다. MC나 진행순서 구성도 인연을 찾는 목적에 집중할 수 있게 짧고 임팩트있고 위트있게 진행하셔서 긴장감 풀어주고 좋았습니다. 

- 참가자수: 너무 많았으면 기빨리고 힘들었을 것 같은데, 이제 넘 힘들당.. 싶던 차에 마지막 로테이션이라하셔서 체력도 고려하셨나!했답니다. 어떻게 보면 짧은 시간에 16명과 소개팅한 것과 매한가지잖아요? 이렇게 지치는데...얼른 인연 만나서 무한 만남 쳇바퀴에서 탈출해야겠다아!!의욕과 나자신과의 마음다짐을 하는 계기도 되어 좋았어요. 

- 뭣보다 김달님!! 화면으로만 뵈었던 김달님이 돌아다니시며 한분한분 정성들여 인사하시고 아이컨택하셔서 모두의 행복한 삶을 위한 인연찾기에 우연은 진정성있단걸 느낄 수 있었어요.  (나중에 끝나고나서 기념사진 시간도 가져주셔서 추억으로도 남았어요 ㅎㅎ)

- 2차 장소를 프로페셔널하게 바로 컨셉별 이런저런 장소가 있다 어디로 가시겠냐 알려주시고 장소 앞까지 길안내를 끝까지 해주신 우연담당분이 계셔서 흐지부지되지않고 2차가서 더 얘기할 수 있었어서 정말 다행이에요. 시간이 아쉽고, 알콜이 들어가야 속얘기 편히 나오는 분들은 2차에서 더 깊이 서로들 알아갈 수 있었답니다. 제 마음의 결정에도 결정적이었어요 ㅎㅎㅎ 장소 추천해주셔서 정말 감사했어요. 
      `,
      date: "24.05.11",
    },
    
  ]);
  
  useEffect(() => {
    // const handleResize = () => {
    //   setCurrentImg(window.innerWidth);
    // };

    // // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    // window.addEventListener("resize", handleResize);

    // // 초기 로딩 시 화면 너비 체크
    // handleResize();
    
    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    

    document.title = "파티 후기 | 우연 소개";
    if(id){
      dummy1?.map((item, i) => {
        if(item.id === parseInt(id)){
          setData(item);
          setName(item.name);
          if(item.stars>0){
            setStars(Array(item.stars).fill('★').join(''));
          }
          try {
            setSentences(item.desc.split('\n').filter(sentence => sentence.trim() !== ''));    
          } catch (error) {
            
          }
          
        }
      });
      
    }
    const ratioBox = document.querySelector('.review-base');
    const content = document.querySelector('.review-content');

    const adjustPadding = () => {
      const width = ratioBox.offsetWidth;
      const height = ratioBox.offsetHeight;

      // var ratio = 1.165625;
      var ratio = 1.166145833333333;
      if(window.innerHeight < 992){
        // ratio = 4.12;
        ratio = 3.119386666666667;
      }
      // try {
      //   ratio = data.ratio.split(':').reduce((a, b) => a / b);  
      //   console.log("lch1721 check ratio: ", ratio);
      // } catch (error) {
      //   console.log("lch1721 check ratio error: ", error);
      //   ratio = 3000 / 2000;
      // }
      // console.log("lch1721 check ratio: ", ratio);
      // if (height < width * ratio) {
      //   content.style.paddingBottom = `${(width * ratio - height) / width * 100}%`;
      //   console.log("lch1721 check padding bottom: ", `${(width * ratio - height) / width * 100}%`);
      // } else {
      //   content.style.paddingBottom = '0';
      // }
      // content.style.paddingBottom = '0';
      setCurrentImg(window.innerWidth);
    };

    adjustPadding();
    window.addEventListener('resize', adjustPadding);

    return () => {
      window.removeEventListener('resize', adjustPadding);
    };
    
  }, [id]);


  return (
    <React.Fragment>
    <div className="review-base"  style={{
            // backgroundRepeat: 'repeat-x' }}> 
             }}> 
    <div className="review-base-image">
              
      <Container style={{
            padding: '14px 14px',
            maxWidth : '1024px',
          }}>
        <Row
          className="w-100 m-0"
          style={{
            borderRadius: '10px',
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: '#00000099',
            padding: '14px 14px'
          }}
        >
          <div className="review-container">
            <div className="review-image-box">
              <img src={data?.image} alt="review" />
            </div>
            <h1>{data?.name}</h1>
            <h2>{data?.header}</h2>
            <div className="review-stars">{stars}</div>
            <div className="review-line"></div>
            <div className="review-content">
              {sentences.map((sentence, index) => (
                <p key={index}>{sentence}</p>
              ))}
            </div>
          </div>
        </Row>  
      </Container>
    </div>
    </div> 
  </React.Fragment>
  );
};

PartyDetail.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(PartyDetail);
