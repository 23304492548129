import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { Col, Container, Row, Modal, Input, Button } from "reactstrap";
// import BackImg from "../../assets/images/small/img-4.jpg";
//i18n
import { withTranslation } from "react-i18next";
import BackImg from "./finish_pc.jpg";
import BackImg1 from "./finish_mobile.jpg";
import "../Dashboard/dashboard.scss";

const RequestFinishPage = (props) => {
  const [currentImg, setCurrentImg] = useState(0);
  const history = useHistory();
  const goHome = () => {
    // console.log("goHome click");
    // window.location.replace('/dashboard');
    // history.push("/dashboard");
    window.location.href = "https://www.coincidence.co.kr/";
  };  
  useEffect(() => {
    const handleResize = () => {
      setCurrentImg(window.innerWidth);
    };

    // 컴포넌트 마운트 시점에 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 초기 로딩 시 화면 너비 체크
    handleResize();
    document.title = "상담신청 완료 | 우리의 인연";
    // 컴포넌트 언마운트 시점에 이벤트 리스너 제거
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
    //   window.location.replace('https://example.com'); // 이동할 URL
      window.location.replace('/dashboard');
    }, 10000);
  
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <React.Fragment>
      <div className="border-bottom">
        {currentImg > 991 ? (
          <div>
            
              <img alt="mainpage" onClick={goHome} className="w-100" src={BackImg}  onDragStart={(e) => e.preventDefault()} />
            
          </div>
        ) : (
          <div>
            
            <img alt="mainpage"  onClick={goHome} className="w-100" src={BackImg1} onDragStart={(e) => e.preventDefault()} />
            
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

RequestFinishPage.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(RequestFinishPage);
